import * as React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

const NotFoundPage = () => {
  return (
    <main>
      <Helmet>
        <title>Phone Ran Outta Battery</title>
      </Helmet>
      <header>
        <h1 className="text-3xl font-bold underline">
          Phone Ran Outta Battery
        </h1>
        <p className="italic">(aka 404 - Not Found)</p>
      </header>
      <p>
        <Link className="underline" to="/">
          Charge it up then check the latest =&gt;
        </Link>
      </p>
    </main>
  );
};

export default NotFoundPage;
